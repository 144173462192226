@charset "UTF-8";
 body {
     position: relative;
     min-height: 100vh;
     font-family: PingFangTC-Regular;
     font-size: 18px;
     line-height: 33px;
     -webkit-font-smoothing: antialiased;
}
 .preload-bg {
     position: fixed;
     top: 0px;
     left: 0px;
     z-index: 9999;
     height: 100vh;
     width: 100vw;
     display: flex;
     display: -ms-flexbox;
     flex-direction: column;
     -ms-flex-direction: column;
     opacity: 1;
     transition: all 0.3s ease-in-out;
     background-image: url(../image/background-web1.png);
     background-repeat: no-repeat;
     background-size: cover;
}
 .preload-bg.ra-hidden {
     opacity: 0;
}
 .preload-bg .bounce {
     border-radius: 50%;
     width: 15px;
     height: 15px;
     background-color: #1B2E41;
}
 .preload-bg .bounce-1 {
     animation: bounce-animation 1.5s infinite;
}
 .preload-bg .bounce-2 {
     animation: bounce-animation 1.5s infinite;
     animation-delay: 0.25s;
}
 .preload-bg .bounce-3 {
     animation: bounce-animation 1.5s infinite;
     animation-delay: 0.5s;
}
 @keyframes bounce-animation {
     75% {
         transform: scale(0);
         -webkit-transform: scale(0);
    }
}
 nav {
     margin-top: 40px;
     margin-bottom: 40px;
     font-size: 18px;
     max-width: 960px;
     left: 50% !important;
     margin-left: -500px;
     transition: all .2s ease-in-out;
}
 nav.ra-nav-bg {
     margin-top: 0px;
     background-color: white;
}
 nav .navbar-toggler {
     border: 1px solid #000000;
     border-radius: 0px;
     padding: 8px;
     color: #000000;
}
 nav .navbar-toggler i.ra-list {
     line-height: 0.75em;
     vertical-align: middle;
     display: inline-block;
}
 nav li.nav-item > a.nav-link, nav li.nav-item > a.nav-link:hover {
     position: relative;
     padding-right: 25px !important;
     padding-left: 25px !important;
     cursor: pointer;
     color: #000000;
}
 nav a.nav-link:active {
     position: relative;
     padding-right: 25px !important;
     padding-left: 25px !important;
     cursor: pointer;
     color: #000000;
     font-weight: 600;
}
 nav li.nav-item > a.nav-link:after {
     content: '';
     position: absolute;
     width: 80px;
     height: 3px;
     border-radius: 2px;
     left: calc(50% - 38px);
     right: 0px;
     bottom: 5px;
     opacity: 0;
     background-color: #437891;
     transition: 300ms;
}
 nav li.nav-item > a.nav-link.active:after, nav li.nav-item > a.nav-link:hover:after {
     bottom: 0px;
     opacity: 1;
}
 .container.ra-container {
     min-height: calc(100vh - 110px);
     max-width: 100vw;
     color: #000000;
     padding-top: 145px;
     padding-bottom: 50px;
     background-image: url(../image/background-web2.png);
     background-repeat: no-repeat;
     margin-top: 38px;
     background-size: cover;
}
 .container.ra-container .title {
     font-size: 20px;
     line-height: 19px;
     margin-bottom: 20px;
}
 .container.ra-container .content {
     font-size: 15px;
     line-height: 30px;
     margin-bottom: 40px;
}
 .container.ra-container ul.content {
     padding-left: 25px;
}
 .container.ra-container .btn-ra-group {
     margin-top: 40px;
}
 .container.ra-container .btn-ra-default, .container.ra-container .btn-ra-default-reverse {
     border-radius: 0px;
     transition: background-color 0.1s linear;
}
 .container.ra-container .btn-ra-default {
     color: #4F9FB5;
     background-color: #fff;
}
 .container.ra-container .btn-ra-default.inactive {
     color: #000000;
     text-decoration: underline;
     background-color: #fff;
     font-size: 18px;
}
 .container.ra-container .btn-ra-default.active {
     background: #D6A544;
     border: 2px solid #D6A544;
     border-radius: 10px;
     font-family: PingFangTC-Semibold;
     font-size: 18px;
     color: #FFFFFF;
     letter-spacing: 0;
}
 .container.ra-container .btn-ra-default.active:hover {
     background-color: rgba(214, 165, 68, 0.8);
}
 .container.ra-container .btn-ra-default-reverse {
     color: #fff;
     border: 1px solid #fff;
     background-color: transparent;
}
 .container.ra-container .btn-ra-default-reverse:hover {
     background-color: #4f9fb5;
}
 .container.ra-container .btn-ra-defaul-navGreen {
     background: #437891;
     border: 2px solid #437891;
     border-radius: 10px;
     font-family: PingFangTC-Semibold;
     font-size: 18px;
     color: #FFFFFF;
     letter-spacing: 0;
     font-weight: 600;
}
 .container.ra-container .btn + .btn {
     margin-left: 15px;
}
 body.ra-preload #product-service {
     opacity: 1;
     transition: all 1.2s ease-in-out;
}
 body.ra-preload #product-service img {
     transform: translateY(0px);
     transition: all 0.8s ease-out;
}
 body.ra-loaded #product-service {
     opacity: 0;
}
 body.ra-loaded #product-service img {
     transform: translateY(100px);
}
footer {
     padding: 18px;
     font-size: 12px;
     color: #1B2E41;
     background-color: #fff;
     line-height: 20px;
     margin-bottom: 10px;
}
 .modal-content {
     color: #000000;
     font-family: Lato, Arial, 微軟正黑體, NotoSans;
}
 .modal-content .modal-title {
     font-size: 16px;
}
 .modal-content .modal-body {
     font-size: 13px;
}
 .modal-content .modal-body .ra-static-info {
     background-color: #f6f6f6;
     border: 1px solid #cccccc;
     padding: 8px;
}
 .modal-content .modal-body .border-danger {
     border: 1px solid #dc3545;
}
 .modal-content .modal-body a {
     color: #3276B1;
}
 .modal-content .modal-footer button.btn {
     font-size: 13px;
}
 .modal-content .modal-footer button.btn.btn-default {
     border-color: #ccc;
}
 .modal-content .modal-footer button.btn.btn-primary {
     border-color: #3276B1;
     background-color: #3276B1;
}
 .btn.focus, .btn:focus, button:focus {
     box-shadow: none;
     outline: none;
}
 .pt30 {
     padding-top: 30px;
}
 .productTitle {
     font-family: PingFangTC-Semibold;
     font-size: 21px;
     color: #000000;
     text-align: left;
     font-weight: 600;
}
 .hide {
     display: none;
}
 .hrLine {
     width: 25%;
     margin: auto;
}
 a.nav-link.active {
     font-weight: 600;
}
 .pl-250 {
     padding-left: 15%;
}
 .pic-width {
     width: 80%;
}
 .office-pic-width {
     width: 48%;
}
 .office1 {
     display: flex;
     margin-right: 10px;
}
 .latest-group-btn {
    text-align: left;
    margin-top: 0px!important;
}
.office-container{
  display: inline-flex!important;
}
 @media (max-width: 415px) {
     .container.ra-container {
         min-height: calc(100vh - 72px);
         max-width: 100vw;
         color: #000000;
         padding-top: 145px;
         padding-bottom: 50px;
         background-image: url(../image/background-mobile2.png);
         padding-top: 15px !important;
         margin-top: 100px;
         background-repeat: no-repeat;
         background-size: auto;
         background-size: cover;
    }
     .row {
         flex-direction: column-reverse;
    }
     .hrLine {
         width: 50px;
         margin: auto;
    }
     .nav-link.activ.show {
         font-weight: 600;
    }
     .pic-width {
         width: 100%;
    }
     .office-pic-width {
         width: 100%;
    }
     .office1 {
         display: none;
    }
}
 @media (max-width: 991.98px) {
     nav {
         left: 0 !important;
         margin-left: 0px;
    }
     nav .navbar-brand {
         margin-left: 50px;
    }
     .container.ra-container .tab-pane {
         padding: 0px 20px;
    }
     .container.ra-container .title {
         line-height: 25px;
    }
     .pl-250 {
         padding-left: 0px;
    }
}
 @media (max-width: 767.98px) {
     .row {
         flex-direction: column-reverse;
    }
     nav {
         margin: 20px auto;
    }
     nav .navbar-brand {
         margin-left: 10px;
    }
     nav ul.navbar-nav {
         background-color: #fff;
         box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.3);
         margin-top: 10px;
         padding: 0px 10px;
    }
     nav ul.navbar-nav li.nav-item {
         padding: 10px 0px;
         text-align: center;
    }
     nav ul.navbar-nav li.nav-item a.nav-link {
         color: #004477;
    }
     nav ul.navbar-nav li.nav-item a.nav-link.active {
         background-color: #C6E2F5;
    }
     nav ul.navbar-nav li.nav-item a.nav-link:active {
         color: #437891;
         background-color: #c6e2f5;
         font-weight: 600;
    }
     nav ul.navbar-nav li.nav-item a.nav-link:after {
         content: none;
    }
     nav ul.navbar-nav li.nav-item + li.nav-item {
         border-top: 1px solid #ddd;
    }
     .container.ra-container {
         padding-top: 105px;
    }
     .container.ra-container .title {
         text-align: center;
         font-size: 18px;
    }
     .container.ra-container .content {
         font-size: 13px;
    }
     .container.ra-container .btn-ra-group {
         text-align: center;
    }
     .latest-group-btn {
         text-align: left!important;
    }
}
 